<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2024-01-20 13:03:32
 * @FilePath: \awx-ui\src\pages\login\phone.vue
-->
<template>
  <div class="phone-login">
    <a-form @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" size="large" placeholder="手机号" v-decorator="['phone', {rules: [{ required: true, message: '请输入手机号', whitespace: true}]}]"></a-input>
      </a-form-item>
      <div class="code">
        <a-form-item class="code-num">
          <a-input class="login-input mgt20" size="large" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
        </a-form-item>
        <div :class="['code-btn', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
      </div>
      <a-form-item>
        <a-button :loading="logging" style="width:100%;height: 50px;border-radius: 4px;margin-top: 48px;font-size: 16px;margin-bottom: 58px;background: #2266F9;" size="large" htmlType="submit" type="primary">{{ logging ? '正在登录中...' : '立即登录' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="register">
      <div>还没有账号？<span @click="register">立即注册</span></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { loadRoutes } from "@/utils/routerUtil";
import { mapMutations } from "vuex";
import { mapState, mapActions } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
export default {
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      isCode: false,
      codeTime: 60,
      timer: null,
    };
  },
  components: {},
  mounted() {},
  computed: {
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setPermissions"]),
    register () {
      this.$emit('register')
    },
    testPhone () {
      const phoneNumberRegex = /^1[3456789]\d{9}$/;
      let flag = true
      const phone = this.form.getFieldValue("phone");
      if (!phone) {
        this.$message.error("请输入手机号码");
        return false
      }
      if (!phoneNumberRegex.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return false
      } 
      return flag
    },
    sendCode() {
      const phone = this.form.getFieldValue("phone");
      if (!this.testPhone()) return false
      if (this.isCode) return false;
      this.isCode = true;
      this.$axiosJsonPost(global.API.smsSend, {phone: phone}).then((res) => {
        this.codeTime--;
        this.timer = setInterval(() => {
          if (this.codeTime === 1) {
            this.isCode = false;
            if (this.timer) clearInterval(this.timer);
            this.codeTime = 60;
            return false;
          } else {
            this.codeTime--;
          }
        }, 1000);
      });
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          const phone = this.form.getFieldValue("phone");
          const code = this.form.getFieldValue("code");
          const phoneNumberRegex = /^1[3456789]\d{9}$/;
          if (!phoneNumberRegex.test(phone)) {
            this.$message.error("请输入正确的手机号码");
            return false
          } 
          this.logging = true;
          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          setTimeout(() => {
            this.logging = false
          }, 4000)
          const self = this;
          axios.post(global.API.smsLogin, {phone, code}, config).then(function (res) {
            if ([200, 201, 204, 202].includes(res.status)) {
              if ([0, 200].includes(res.data.code)) {
                localStorage.setItem("token", res.data.token);
                /*这里做处理.....*/
                self.getUserInfo(res);
              } else {
                self.$message.error(res.data.msg)
              }
            }
          });
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      this.logging = false;
      // setAuthorization({ sessionId: loginRes.sessionId });
      const username = this.form.getFieldValue("phone");
      const isSuperuser = res.data.is_superuser;
      this.setUser({...res.data, username, isSuperuser });
      this.getRouter();
      this.$message.success("登录成功");
    },
    getRouter() {
      const self = this;
      this.$axiosGet(global.API.getMemus + "/root", {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          localStorage.setItem("routerData", JSON.stringify(res.data));
          const routes = self.filterASyncRoutes(res.data);
          const index = self.$router.options.routes.findIndex(
            (item) => item.path === "/"
          );
          self.$router.options.routes[index].children =
            routes[0]?.children || [];
          formatRoutes(self.$router.options.routes);
          self.$router.addRoutes(self.$router.options.routes);
          loadRoutes();
          self.logging = false
          self.$router.push("/dashboard");
        }
      });
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
    getMenuData() {
      let menuData = this.$store.state.setting.menuData || [];
      this.$axiosGet(global.API.quickaccess, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          menuData.forEach((item) => {
            if (item.path === "quickaccess") {
              item.children = [];
              res.data.map((quickItem) => {
                item.children.push({
                  name: quickItem.name,
                  meta: {
                    notAlive: true,
                    params: {
                      quickId: quickItem.quick_ids[0],
                    },
                    obj: quickItem,
                    authority: {
                      permission: "*",
                    },
                    permission: [
                      {
                        permission: "*",
                      },
                      {
                        permission: "*",
                      },
                    ],
                  },
                  fullPath: `/quickaccess/quickDetail/${quickItem.quick_ids[0]}`,
                  path: `quick-detail/${quickItem.quick_ids[0]}`,
                  component: () => import("@/pages/quickaccess/quickDetail"),
                });
              });
            }
          });
        }
        this.$store.commit("setting/setMenuData", menuData);
        this.$store.commit("setting/setCurrentTab", '1');
        loadRoutes();
        this.$router.push("/dashboard");
      });
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>
<style scoped lang='less'>
.phone-login {
  margin-top: 42px;
  /deep/ .ant-input {
    font-size: 15px;
  }
  .login-input {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid rgba(196,204,219,1);
    border-radius: 4px;
    text-indent: 12px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #2266F9;
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .register {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #1D202D;
    line-height: 22px;
    font-weight: 400;
    span {
      font-size: 16px;
      color: #2266F9;
      line-height: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .code {
    display: flex;
    .code-num {
      flex: 1;
    }
    .code-btn {
      margin-top: 20px;
      width: 120px;
      height: 50px;
      text-align: center;
      line-height: 48px;
      background: #ffffff;
      border: 1px solid rgba(34, 102, 249, 1);
      border-radius: 4px;
      color: #2266f9;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      margin-left: 20px;
      // &:hover {
      //    border: 1px solid rgba(34,102,249,1);
      // }
    }
    .code-btn-send {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid rgba(196, 204, 219, 1);
    }
  }
}
</style>