var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {rules: [{ required: true, message: '请输入账号', whitespace: true}]}]),expression:"['name', {rules: [{ required: true, message: '请输入账号', whitespace: true}]}]"}],staticClass:"login-input",attrs:{"placeholder":"输入账号"}})],1),_c('a-form-item',[(!_vm.isShow)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input mgt20",attrs:{"type":"password","placeholder":"输入密码"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input mgt20",attrs:{"placeholder":"请输入密码"}}),_c('span',{staticStyle:{"position":"absolute","cursor":"pointer","right":"14px","top":"-6px"},on:{"click":function($event){$event.stopPropagation();_vm.isShow = !_vm.isShow}}},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":!_vm.isShow ? 'eye-invisible' : 'eye'}})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","height":"50px","border-radius":"4px","margin-top":"48px","font-size":"16px","margin-bottom":"58px","background":"#2266F9"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(_vm._s(_vm.logging ? '正在登录中...' : '立即登录'))])],1)],1),_c('div',{staticClass:"register"},[_c('div',[_vm._v("还没有账号？"),_c('span',{on:{"click":_vm.register}},[_vm._v("立即注册")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }