<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2023-12-07 17:33:38
 * @FilePath: \awx-ui\src\pages\login\register.vue
-->
<template>
  <div class="register-page">
    <div class="register-title">注册账号</div>
    <a-form @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" size="large" placeholder="请输入手机号码" v-decorator="['phone', {rules: [{ required: true, message: '请输入手机号码', whitespace: true}]}]"></a-input>
      </a-form-item>
      <div class="code">
        <a-form-item class="code-num">
          <a-input class="login-input" size="large" placeholder="验证码" v-decorator="['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"></a-input>
        </a-form-item>
        <div :class="['code-btn', isCode && 'code-btn-send']" @click="sendCode">{{ isCode ? codeTime : '发送验证码' }}</div>
      </div>
      <a-form-item>
        <a-input
          v-if="!isShow"
          type="password"
          class="login-input"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="输入密码"
        />
        <a-input
          v-else
          class="login-input"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次确认密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-input
          v-if="!isShowAgain"
          type="password"
          class="login-input"
          v-decorator="[
            'confirmPassword',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次输入密码"
        />
        <a-input
          v-else
          class="login-input"
          v-decorator="[
            'confirmPassword',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="再次输入密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShowAgain = !isShowAgain">
          <svg-icon :icon-class="!isShowAgain ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-button :loading="logging" style="width:100%;height: 50px;border-radius: 4px;margin-top: 14px;font-size: 16px;margin-bottom: 38px;background: #2266F9;" size="large" htmlType="submit" type="primary">{{ logging ? '正在注册中...' : '立即注册' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="login">
      <div>
        已有账号？
        <span @click="login">立即登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      logging: false,
      form: this.$form.createForm(this),
      isCode: false,
      codeTime: 60,
      timer: null,
      isShow: false,
      isShowAgain: false
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    login() {
      this.$emit("login");
    },
    testPhone () {
      const phoneNumberRegex = /^1[3456789]\d{9}$/;
      let flag = true
      const phone = this.form.getFieldValue("phone");
      if (!phone) {
        this.$message.error("请输入手机号码");
        return false
      }
      if (!phoneNumberRegex.test(phone)) {
        this.$message.error("请输入正确的手机号码");
        return false
      } 
      return flag
    },
    sendCode() {
      const phone = this.form.getFieldValue("phone");
      if (!this.testPhone()) return false
      if (this.isCode) return false;
      this.isCode = true;
      this.$axiosJsonPost(global.API.smsSend, {phone: phone}).then((res) => {
        this.codeTime--;
        this.timer = setInterval(() => {
          if (this.codeTime === 1) {
            this.isCode = false;
            if (this.timer) clearInterval(this.timer);
            this.codeTime = 60;
            return false;
          } else {
            this.codeTime--;
          }
        }, 1000);
      });
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          const phone = this.form.getFieldValue("phone");
          const code = this.form.getFieldValue("code");
          const password = this.form.getFieldValue("password");
          const confirmPassword = this.form.getFieldValue("confirmPassword");
          const phoneNumberRegex = /^1[3456789]\d{9}$/;
          if (!phoneNumberRegex.test(phone)) {
            this.$message.error("请输入正确的手机号码");
            return false
          } 
          if (password !== confirmPassword) {
            this.$message.error("两次密码输入不一致");
            return false
          }
          this.logging = true;
          let config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const self = this;
          setTimeout(() => {
            this.logging = false
          }, 4000)
          axios.post(global.API.registry, {phone, code, password}, config).then(function (res) {
            self.logging = false
            if ([200, 201, 204, 202].includes(res.status)) {
              if ([0, 200].includes(res.data.code)) {
                /*这里做处理.....*/
                this.$emit("login");
              } else {
                self.$message.error(res.data.msg)
              }
            }
          });
        }
      });
    },
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
};
</script>
<style scoped lang='less'>
.register-page {
  /deep/ .ant-input {
    font-size: 15px;
  }
  .register-title {
    font-size: 28px;
    color: #1d202d;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .login-input {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(196, 204, 219, 1);
    border-radius: 4px;
    text-indent: 12px;
    margin-top: 12px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #2266f9;
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .code {
    display: flex;
    .code-num {
      flex: 1;
    }
    .code-btn {
      margin-top: 12px;
      width: 120px;
      height: 50px;
      text-align: center;
      line-height: 48px;
      background: #ffffff;
      // border: 1px solid rgba(196,204,219,1);
      border: 1px solid rgba(34, 102, 249, 1);
      border-radius: 4px;
      color: #2266f9;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      margin-left: 20px;
      // &:hover {
      //    border: 1px solid rgba(34,102,249,1);
      // }
    }
    .code-btn-send {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid rgba(196, 204, 219, 1);
    }
  }
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #1d202d;
    line-height: 22px;
    font-weight: 400;
    span {
      font-size: 16px;
      color: #2266f9;
      line-height: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>