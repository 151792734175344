<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:53:56
 * @LastEditTime: 2024-01-19 17:31:59
 * @FilePath: \awx-ui\src\pages\login\scan-code.vue
-->
<template>
  <div class="scan-code">
    <div class="qr-code">
      <img
        :src="src"
        alt=""
        style="width: 98%;"
      >
    </div>
    <div class="tips">使用微信扫一扫二维码登录</div>
  </div>
</template>

<script>
import { loadRoutes } from "@/utils/routerUtil";
import { mapState, mapActions, mapMutations } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";

export default {
  props: {
    tab: String,
  },
  data () {
    return {
      src: '',
      timer: null,
      uuid: ''
    };
  },
  components: {},
  mounted () {
    this.uuid = global.utils.generateUUID()
    this.getCode()
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setPermissions"]),

    getCode () {
      clearInterval(this.timer)
      this.timer = null
      let apiAjax = global.API.getQrCode + '?uuid=' + this.uuid
      fetch(apiAjax, {
        method: 'GET',
        headers: {
          'token': localStorage.getItem('token')
        }
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            this.$message.error('当前二维码获取失败')
          }
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          this.src = url
          clearInterval(this.timer)
          this.timer = null 
          this.timer = setInterval(() => {
            if (this.$store.state.setting.currentTab === '3') { 
              this.getStatus()
            } else {
              clearInterval(this.timer)
              this.timer = null
            }
          }, 3000)
        })
        .catch(error => {
          this.$message.error('当前二维码获取失败')
        });
    },
    // 获取状态
    getStatus () {
      const self = this
      this.$axiosGet(global.API.getWxStatus, {uuid: this.uuid}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.code === 200) {
            clearInterval(self.timer);
            self.timer = null
            self.wxLogin(res.data.data)
          }
        }
      });
    },
    wxLogin (data) {
      const self = this
      this.$axiosGet(global.API.wxLogin, { openId: data.openId, uuid: this.uuid }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          localStorage.setItem("token", res.data.token);
          self.getUserInfo(res);
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      const username = res.data.username
      const isSuperuser = res.data.is_superuser;
      this.setUser({ ...res.data, username, isSuperuser });
      this.getRouter();
      this.$message.success("登录成功");
    },
    getRouter() {
      const self = this;
      this.$axiosGet(global.API.getMemus + "/root", {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          localStorage.setItem("routerData", JSON.stringify(res.data));
          const routes = self.filterASyncRoutes(res.data);
          const index = self.$router.options.routes.findIndex(
            (item) => item.path === "/"
          );
          self.$router.options.routes[index].children =
            routes[0]?.children || [];
          formatRoutes(self.$router.options.routes);
          self.$router.addRoutes(self.$router.options.routes);
          loadRoutes();
          self.$router.push("/dashboard");
        }
      });
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
    getMenuData() {
      let menuData = this.$store.state.setting.menuData || [];
      this.$axiosGet(global.API.quickaccess, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          menuData.forEach((item) => {
            if (item.path === "quickaccess") {
              item.children = [];
              res.data.map((quickItem) => {
                item.children.push({
                  name: quickItem.name,
                  meta: {
                    notAlive: true,
                    params: {
                      quickId: quickItem.quick_ids[0],
                    },
                    obj: quickItem,
                    authority: {
                      permission: "*",
                    },
                    permission: [
                      {
                        permission: "*",
                      },
                      {
                        permission: "*",
                      },
                    ],
                  },
                  fullPath: `/quickaccess/quickDetail/${quickItem.quick_ids[0]}`,
                  path: `quick-detail/${quickItem.quick_ids[0]}`,
                  component: () => import("@/pages/quickaccess/quickDetail"),
                });
              });
            }
          });
        }
        this.$store.commit("setting/setMenuData", menuData);
        this.$store.commit("setting/setCurrentTab", '1');
        clearInterval(this.timer)
        this.timer = null
        loadRoutes();
        this.$router.push("/dashboard");
      });
    },
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  } 
};
</script>
<style scoped lang='less'>
.scan-code {
  text-align: center;
  .qr-code {
    margin: 52px auto;
    width: 220px;
    height: 220px;
    border: 1px solid rgba(196, 204, 219, 1);
    border-radius: 6px;
  }
  .tips {
    font-size: 18px;
    color: #1d202d;
    font-weight: 400;
  }
}
</style>