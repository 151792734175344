<template>
  <common-layout>
    <div class="login">
      <div class="left">
        <div class="welcome">欢迎使用</div>
        <div class="system-name">安全运维管理平台</div>
      </div>
      <div class="right">
        <a-tabs v-show="showLogin" v-model="activeKey" @change="callback">
          <a-tab-pane key="1" tab="账号密码登录">
            <account @register="toRegister" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="手机验证码登录" force-render>
            <phone @register="toRegister" />
          </a-tab-pane>
          <a-tab-pane key="3" tab="微信扫码登录">
            <ScanCode v-if="activeKey === '3'" />
          </a-tab-pane>
        </a-tabs>
        <register v-show="!showLogin"  @login="toLogin" />
      </div>
    </div>
    <!-- <div class="foooter-link">
      <a href="#">皖ICP备2022011188号</a >
    </div> -->
  </common-layout>
</template>

<script>
import CommonLayout from "@/layouts/CommonLayout";
import account from "./account.vue";
import phone from './phone.vue'
import ScanCode from './scan-code.vue'
import register from './register.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: "Login",
  components: { CommonLayout, account, register, phone, ScanCode },
  data() {
    return {
      activeKey: '1',
      showLogin: true,
    };
  },
  computed: {
    ...mapState("setting", ["footerLinks", "copyright", 'currentTab']),
  },
  methods: {
    callback(key) {
      this.activeKey = key
      this.$store.commit("setting/setCurrentTab", key);
    },
    toRegister () {
      this.showLogin = false
    },
    toLogin () {
      this.showLogin = true
    }
  },
};
</script>

<style lang="less" scoped>
.common-layout {
  .login {
    position: absolute;
    /* 水平垂直居中 */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 880px;
    height: 560px;
    background: #fdfeff;
    box-shadow: 0px 6px 20px 0px rgba(62, 79, 106, 0.08);
    border-radius: 18px;
    display: flex;

    .left {
      width: 248px;
      min-width: 248px;
      height: 100%;
      background-color: rgba(10, 95, 255);
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      background-image: url("~@/assets/img/login-left.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 58px 0 0 0;
      .welcome {
        font-size: 30px;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        margin-bottom: 10px;
      }
      .system-name {
        font-size: 24px;
        color: #ffffff;
        text-align: center;
        line-height: 44px;
        font-weight: 300;
      }
    }
    .right {
      flex: 1;
      padding: 48px 70px 0 70px;
      /deep/ .ant-tabs-tab {
        font-size: 20px;
        color: #5f687a;
        margin-right: 56px;
        &:last-child {
          margin-right: 0;
        }
      }
      /deep/ .ant-tabs-ink-bar {
        height: 3px;
      }
      /deep/ .ant-tabs-nav .ant-tabs-tab:hover, /deep/ .ant-tabs-tab-active {
        color: #2266f9;
      }
      /deep/ .ant-tabs-tab-active {
        font-weight: 550;
      }
    }
  }
}
</style>
