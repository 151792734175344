var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-page"},[_c('div',{staticClass:"register-title"},[_vm._v("注册账号")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone', {rules: [{ required: true, message: '请输入手机号码', whitespace: true}]}]),expression:"['phone', {rules: [{ required: true, message: '请输入手机号码', whitespace: true}]}]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":"请输入手机号码"}})],1),_c('div',{staticClass:"code"},[_c('a-form-item',{staticClass:"code-num"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]),expression:"['code', {rules: [{ required: true, message: '验证码', whitespace: true}]}]"}],staticClass:"login-input",attrs:{"size":"large","placeholder":"验证码"}})],1),_c('div',{class:['code-btn', _vm.isCode && 'code-btn-send'],on:{"click":_vm.sendCode}},[_vm._v(_vm._s(_vm.isCode ? _vm.codeTime : '发送验证码'))])],1),_c('a-form-item',[(!_vm.isShow)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input",attrs:{"type":"password","placeholder":"输入密码"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input",attrs:{"placeholder":"再次确认密码"}}),_c('span',{staticStyle:{"position":"absolute","cursor":"pointer","right":"14px","top":"-6px"},on:{"click":function($event){$event.stopPropagation();_vm.isShow = !_vm.isShow}}},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":!_vm.isShow ? 'eye-invisible' : 'eye'}})],1)],1),_c('a-form-item',[(!_vm.isShowAgain)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPassword',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'confirmPassword',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input",attrs:{"type":"password","placeholder":"再次输入密码"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'confirmPassword',
          { rules: [{ required: true, message: '密码不能为空!' }] },
        ]),expression:"[\n          'confirmPassword',\n          { rules: [{ required: true, message: '密码不能为空!' }] },\n        ]"}],staticClass:"login-input",attrs:{"placeholder":"再次输入密码"}}),_c('span',{staticStyle:{"position":"absolute","cursor":"pointer","right":"14px","top":"-6px"},on:{"click":function($event){$event.stopPropagation();_vm.isShowAgain = !_vm.isShowAgain}}},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":!_vm.isShowAgain ? 'eye-invisible' : 'eye'}})],1)],1),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%","height":"50px","border-radius":"4px","margin-top":"14px","font-size":"16px","margin-bottom":"38px","background":"#2266F9"},attrs:{"loading":_vm.logging,"size":"large","htmlType":"submit","type":"primary"}},[_vm._v(_vm._s(_vm.logging ? '正在注册中...' : '立即注册'))])],1)],1),_c('div',{staticClass:"login"},[_c('div',[_vm._v(" 已有账号？ "),_c('span',{on:{"click":_vm.login}},[_vm._v("立即登录")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }