<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-16 14:52:58
 * @LastEditTime: 2024-06-06 15:01:18
 * @FilePath: \awx-ui\src\pages\login\account.vue
-->
<template>
  <div class="account-login">
    <a-form @submit="onSubmit" :form="form">
      <a-form-item>
        <a-input class="login-input" placeholder="输入账号" v-decorator="['name', {rules: [{ required: true, message: '请输入账号', whitespace: true}]}]"></a-input>
      </a-form-item>
      <a-form-item>
        <a-input
          v-if="!isShow"
          type="password"
          class="login-input mgt20"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="输入密码"
        />
        <a-input
          v-else
          class="login-input mgt20"
          v-decorator="[
            'password',
            { rules: [{ required: true, message: '密码不能为空!' }] },
          ]"
          placeholder="请输入密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item>
        <a-button :loading="logging" style="width:100%;height: 50px;border-radius: 4px;margin-top: 48px;font-size: 16px;margin-bottom: 58px;background: #2266F9;" size="large" htmlType="submit" type="primary">{{ logging ? '正在登录中...' : '立即登录' }}</a-button>
      </a-form-item>
    </a-form>
    <div class="register">
      <div>还没有账号？<span @click="register">立即注册</span></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { loadRoutes } from "@/utils/routerUtil";
import { mapMutations } from "vuex";
import { mapState, mapActions } from "vuex";
import { formatRoutes } from "@/utils/routerUtil";
import dynamicComponents from "@/utils/dynamic-components";
export default {
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this),
      isShow: false,
    };
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState("setting", ["footerLinks", "copyright"]),
  },
  methods: {
    ...mapMutations("account", ["setUser", "setPermissions", "setRoles"]),
    ...mapActions("masterMd", ["setHasMaster"]),
    ...mapActions("fileManage", [
      "setClusterId",
    ]),
    register () {
      this.$emit('register')
    },
    onSubmit(e) {
      e.preventDefault();
      // 修复用户登录菜单不刷新BUG
      // this.$store.commit('setting/setMenuData', undefined)
      this.form.validateFields((err) => {
        if (!err) {
          this.logging = true
          const username = this.form.getFieldValue("name");
          const password = this.form.getFieldValue("password");
          let formData = new FormData();
          formData.append("username", username);
          formData.append("password", password);
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const self = this;
          setTimeout(() => {
            this.logging = false
          }, 4000)
          axios.post(global.API.login, formData, config).then(function (res) {
            if ([200, 201, 204, 202].includes(res.status)) {
              localStorage.setItem("token", res.data.token);
              /*这里做处理.....*/
              self.getUserInfo(res);
            } else {
              this.logging = false
            }
          });
        }
      });
    },
    getUserInfo() {
      const self = this;
      this.$axiosGet(global.API.getUserInfo, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          self.afterLogin(res);
        }
      });
    },
    afterLogin(res) {
      // setAuthorization({ sessionId: loginRes.sessionId });
      const username = this.form.getFieldValue("name");
      const password = this.form.getFieldValue("password");
      const isSuperuser = res.data.is_superuser;
      this.setUser({ ...res.data, username, isSuperuser });
      this.getMaster()
      this.getRouter();
      this.$message.success("登录成功");
    },
    getMaster () {
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            const onlineCluster = res.data.results.filter(item => item.status === 'online')
            let DefaultMaster = {}
            if (onlineCluster.length > 0) {
              this.setHasMaster(true)
              const arr = onlineCluster.filter(item => item.is_default)
              if (arr.length > 0) {
                DefaultMaster = arr[0]
              } else {
                DefaultMaster = onlineCluster[0]
              }
            } else {
              this.setHasMaster(false)
            }
            localStorage.setItem('FILE_SYSTEM_URL', DefaultMaster?.public_addr || DefaultMaster?.private_addr || '')
            window.config.FILE_SYSTEM_URL = DefaultMaster.public_addr || DefaultMaster.private_addr || ''
            this.setClusterId(DefaultMaster.id)
          } else {
            localStorage.setItem('FILE_SYSTEM_URL', '')
            this.setClusterId('')
            window.config.FILE_SYSTEM_URL = ''
          }
        }
      });
    },
    getRouter() {
      const self = this;
      this.$axiosGet(global.API.getMemus + "/root", {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          localStorage.setItem("routerData", JSON.stringify(res.data));
          const routes = self.filterASyncRoutes(res.data);
          const index = self.$router.options.routes.findIndex(
            (item) => item.path === "/"
          );
          self.$router.options.routes[index].children =
            routes[0]?.children || [];
          formatRoutes(self.$router.options.routes);
          self.$router.addRoutes(self.$router.options.routes);
          loadRoutes();
          this.logging = false;
          self.$router.push("/dashboard");
        }
      });
    },
    // 路由过滤  遍历路由 转换为组件对象和路径
    filterASyncRoutes(data) {
      const routes = data.filter((item) => {
        const getComp = dynamicComponents[`${item.component}`];
        item["component"] = getComp;
        // 路由递归，转换组件对象和路径
        if (item["children"] && item["children"].length > 0) {
          item["children"] = this.filterASyncRoutes(item.children);
        }
        return true;
      });
      // 排序
      // routes.sort((a, b) => a['id'] - b['id'])
      return routes;
    },
    getMenuData() {
      let menuData = this.$store.state.setting.menuData || [];
      this.$axiosGet(global.API.quickaccess, {}).then((res) => {
        if ([200, 201, 202, 204].includes(res.status)) {
          menuData.forEach((item) => {
            if (item.path === "quickaccess") {
              item.children = [];
              res.data.map((quickItem) => {
                item.children.push({
                  name: quickItem.name,
                  meta: {
                    notAlive: true,
                    params: {
                      quickId: quickItem.quick_ids[0],
                    },
                    obj: quickItem,
                    authority: {
                      permission: "*",
                    },
                    permission: [
                      {
                        permission: "*",
                      },
                      {
                        permission: "*",
                      },
                    ],
                  },
                  fullPath: `/quickaccess/quickDetail/${quickItem.quick_ids[0]}`,
                  path: `quick-detail/${quickItem.quick_ids[0]}`,
                  component: () => import("@/pages/quickaccess/quickDetail"),
                });
              });
            }
          });
        }
        this.$store.commit("setting/setMenuData", menuData);
        loadRoutes();
        this.$store.commit("setting/setCurrentTab", '1');
        this.$router.push("/dashboard");
      });
    },
  },
};
</script>
<style scoped lang='less'>
.account-login {
  margin-top: 42px;
  /deep/ .ant-input {
    font-size: 15px;
  }
  .login-input {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid rgba(196,204,219,1);
    border-radius: 4px;
    text-indent: 12px;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #2266F9;
    }
  }
  /deep/ .has-error {
    .login-input {
      border-color: #f5222f;
    }
  }
  .register {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: #1D202D;
    line-height: 22px;
    font-weight: 400;
    span {
      font-size: 16px;
      color: #2266F9;
      line-height: 22px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>